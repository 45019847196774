@import '_main';

#trustedby {
    min-height: auto;
    padding: 140px 0;

    @include media-breakpoint-down(lg) {
        padding: 72px 0;
    }
}

#trustedby h1 {
    font-style: normal;
    font-weight: $bold;
    font-size: 3.25rem;
    line-height: 3.75rem;
    padding: 0;
    margin: 0;
    margin-bottom: 48px;

    @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
        line-height: 2.75rem;
        margin-bottom: 24px;
    }
}

#trustedby a.partner {
    display: flex;
    flex: 0 1 0;
    width: 15%;
    min-width: 15%;
    height: 120px;
    cursor: pointer;
    transition: all 150ms linear;
    margin-bottom: 20px;
    padding: 12px 24px;
    background: #f7f7f7;
    border-radius: 18px;

    @include media-breakpoint-down(xl) {
        width: 18%;
        min-width: 18%;
    }

    @include media-breakpoint-down(lg) {
        width: 23%;
        min-width: 23%;
    }

    @include media-breakpoint-down(md) {
        width: 31%;
        min-width: 31%;
    }

    @include media-breakpoint-down(sm) {
        width: 46.5%;
        min-width: 46.5%;
    }
}

#trustedby a.partner:hover {
    cursor: pointer;
    transform: scale(1.025);
}

#trustedby a.partner img {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    object-fit: contain;
}

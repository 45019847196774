@import '_main';

#trustlayer {
    background: linear-gradient(180deg, #07223F 0.01%, #111A7A 67.78%, #2348BC 74.5%, #0375ED 100%);
    color: $color-light;
    padding-top: 148px;
    padding-bottom: 192px;

    @include media-breakpoint-down(lg) {
        padding-top: 72px;
        padding-bottom: 128px;
    }
}

#trustlayer h1 {
    font-style: normal;
    font-weight: $regular;
    font-size: 2.625rem;
    line-height: 3.125rem;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    @include media-breakpoint-down(lg) {
        margin-top: 4rem;
    }
}

#trustlayer h1 strong {
    font-weight: $bold;
}

#trustlayer h2 {
    font-style: normal;
    font-weight: $bold;
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding: 0;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 20px;
}

#trustlayer p {
    font-style: normal;
    font-weight: $regular;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    padding: 0;
    margin: 0;
}

#trustlayer .uvp-container {
    margin-top: 48px;
}

#trustlayer .trust-layers-wrapper {
    width: 635px;
    height: 635px;
    margin-top: 100px;

    @include media-breakpoint-down(lg) {
        width: 320px;
        height: 320px;
        margin-top: 0px;
    }
}

#trustlayer .trust-layers-wrapper img.trust-layers {
    width: 100%;
    height: auto;
}

#trustlayer .trust-layers-wrapper img.trust-layers-shadows {
    position: absolute;
    left: -95px;
    bottom: -170px;
    width: 1015px;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

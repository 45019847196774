@import '_main';

.link-btn,
.link-btn:hover,
.link-btn:focus {
    color: inherit;
    text-decoration: none !important;
}

.link-btn {
    transition: all 0.2s ease-in-out;
}

.link-btn:hover {
    transform: scale(1.05);
}

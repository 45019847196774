@import '_main';

#partnering {
    background: linear-gradient(180deg, #e5eaeb 0%, #edf1f5 66.77%, #f2f5f9 75.69%, #dce4e4 87.81%);
    padding-top: 92px;
    padding-bottom: 128px;

    @include media-breakpoint-down(lg) {
        padding-top: 24px;
        padding-bottom: 128px;
    }
}

#partnering h1 {
    font-style: normal;
    font-weight: $regular;
    font-size: 2.625rem;
    line-height: 3.125rem;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;

    @include media-breakpoint-down(lg) {
        margin-top: 0;
    }
}

#partnering h1 strong {
    font-weight: $bold;
}

#partnering .blue-icon-wrapper {
    margin-right: 24px;
}

#partnering h2 {
    font-style: normal;
    font-weight: $bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
}

#partnering p {
    font-style: normal;
    font-weight: $regular;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    padding: 0;
    margin: 0;
}

#partnering .uvp-container {
    max-width: 480px;
    margin-top: 48px;
}

#partnering .lense-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

#partnering .lense-illu-container {
    position: relative;
    width: 460px;
    min-width: 460px;
    height: 360px;
    min-height: 360px;

    @include media-breakpoint-down(lg) {
        transform: scale(0.75);
    }
}

#partnering .lense-illu-container .lense-large {
    position: absolute;
    height: 330px;
    left: 0px;
    bottom: 0px;
    animation: lenseFloatVerticalLarge 6s infinite alternate ease-in-out;
}

#partnering .lense-illu-container .lense-medium {
    position: absolute;
    height: 230px;
    left: 160px;
    top: 20px;
    animation: lenseFloatVerticalMedium 6s infinite alternate ease-in-out;
}

#partnering .lense-illu-container .lense-small {
    position: absolute;
    height: 160px;
    top: 0px;
    right: 0px;
    animation: lenseFloatVerticalSmall 6s infinite alternate ease-in-out;
}

@keyframes lenseFloatVerticalSmall {
    0% {
        transform: translate(0px);
    }
    20% {
        transform: translate(0px);
    }
    50% {
        transform: translate(10px, -5px);
    }
    80% {
        transform: translate(0px);
    }
    100% {
        transform: translate(0px);
    }
}

@keyframes lenseFloatVerticalMedium {
    0% {
        transform: translate(0px);
    }
    20% {
        transform: translate(0px);
    }
    40% {
        transform: translate(14px, -7px);
    }
    60% {
        transform: translate(14px, -7px);
    }
    80% {
        transform: translate(0px);
    }
    100% {
        transform: translate(0px);
    }
}
@keyframes lenseFloatVerticalLarge {
    0% {
        transform: translate(0px);
    }
    40% {
        transform: translate(20px, -10px);
    }
    60% {
        transform: translate(20px, -10px);
    }
    100% {
        transform: translate(0px);
    }
}

@import '_main';

#showcase {
    background: $color-light;
    color: $color-dark;
    padding-top: 148px;
    padding-bottom: 64px;

    @include media-breakpoint-down(lg) {
        padding-top: 72px;
        padding-bottom: 48px;
    }
}

#showcase h1 {
    text-align: center;
    font-style: normal;
    font-weight: $regular;
    font-size: 3.25rem;
    line-height: 3.75rem;
    font-weight: $bold;
    padding: 0;
    margin: 0;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }
}

#showcase p {
    color: #000000;
    font-style: normal;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0;
    margin: 0;
    margin-bottom: 48px;
    width: 100%;
    max-width: 480px;

    @include media-breakpoint-down(lg) {
        margin-top: 32px;
        margin-bottom: 24px;
    }
}

#showcase .nav-tabs {
    border: none;
}

#showcase .nav-tabs .nav-link {
    position: relative;
    border: none;
    color: #c4c4c4;
    font-size: 1.125rem;
    font-weight: $bold;
    margin: 0px 18px;
    padding: 12px 0px;
    padding-bottom: 12px;
    transition: all 150ms linear;

    @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
        margin: 0px 8px 6px 8px;
    }
}

#showcase .nav-tabs .nav-link::before {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 7px;
    border-radius: 20px;
    background-color: #c4c4c4;
    transition: all 150ms linear;

    @include media-breakpoint-down(sm) {
        height: 4px;
        border-radius: 10px;
    }
}

#showcase .nav-tabs .nav-link:hover,
#showcase .nav-tabs .nav-link.active {
    color: #000000;
}

#showcase .nav-tabs .nav-link:hover::before,
#showcase .nav-tabs .nav-link.active::before {
    background-color: #000000;
}

#showcase .tab-pane {
    padding-top: 68px;

    @include media-breakpoint-down(lg) {
        padding-top: 32px;
    }
}

#showcase .tab-pane a {
    color: #000000;
}

#showcase .tab-pane .border-bottom {
    border-color: #000000 !important;
}

#showcase .tab-pane img {
    width: 100%;
    max-width: 480px;
}

#showcase .tab-pane.fade {
    transition: all 0.3s linear;
    opacity: 0;
}

#showcase .tab-pane.fade.show {
    opacity: 1;
    transition-delay: 150ms;
}

@import '_main';

#welcome {
    opacity: 0;
    justify-content: space-between;
    background: linear-gradient(180deg, #f5f5f7 0%, #eae8f3 66.77%, #e5e1ea 87.81%);
    min-height: max(760px, 100vh);
}

#welcome-content {
    z-index: 2;

    @include media-breakpoint-down(lg) {
        margin-top: 20px;
        padding-bottom: 60px;
    }
}

#welcome .bg-lightning {
    position: absolute;
    top: 48%;
    left: 0;
    right: 0;
    height: 300px;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 22.13%,
        rgba(255, 255, 255, 0.53) 52.46%,
        rgba(255, 255, 255, 0) 78%
    );

    @include media-breakpoint-down(lg) {
        top: 20%;
    }
}

#welcome h1 {
    font-style: normal;
    font-weight: $bold;
    font-size: 6rem;
    line-height: 7rem;
    padding: 0;
    margin: 0;
    z-index: 2;

    @include media-breakpoint-down(xl) {
        font-size: 5rem;
        line-height: 6rem;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 1.25rem;
        font-size: 4rem;
        line-height: 4.8rem;
        margin-top: -24px;
    }

    @include media-breakpoint-down(md) {
        font-size: 3.5rem;
        line-height: 4rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 3rem;
        line-height: 3.5rem;
        margin-top: -60px;
    }
}

#welcome p {
    font-style: normal;
    font-weight: $light;
    font-size: 1.125rem;
    margin-top: 20px;
    margin-bottom: 40px;
}

#welcome a strong {
    border-color: $color-dark !important;
}

#welcome .scroll-cta-container {
    position: absolute;
    bottom: 48px;
    right: 0px;
    cursor: pointer;
    text-decoration: none;
    color: $color-dark;
    z-index: 2;
}

#welcome .arrow-icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-dark;
}

#welcome .arrow-icon {
    object-fit: contain;
}

#welcome .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 10%;
    z-index: 1;
    opacity: 0;
}

#welcome .dot .dot-inner-layer-1,
#welcome .dot .dot-inner-layer-2,
#welcome .dot .dot-inner-layer-3 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffffff;
}

#welcome .dot .dot-inner-layer-1 {
    filter: blur(2px);
}

#welcome .dot .dot-inner-layer-2 {
    opacity: 0.2;
    filter: blur(9px);
}

#welcome .dot .dot-inner-layer-3 {
    opacity: 0.8;
    filter: blur(14px);
}

#welcome svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100vw;
    min-width: 100vw;
    max-height: 70vh;
    transform-origin: top right;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: -1;
}

#welcome .crystal-illu-container {
    position: relative;
    width: 330px;
    height: 410px;
    margin-top: 15%;

    @include media-breakpoint-down(lg) {
        margin-top: 0;
        transform: scale(0.7);
    }

    @include media-breakpoint-down(sm) {
        margin-top: -80px;
        transform: scale(0.5);
    }
}

#welcome .crystal-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

#welcome .crystal-illu-container .crystals-shadows {
    position: absolute;
    bottom: -170px;
    left: -370px;
    width: 912px;
    z-index: -1;
}

#welcome .crystal-illu-container .crystal-large {
    position: absolute;
    height: 330px;
    left: 30px;
    top: 80px;
    animation: crystalFloatVerticalLarge 7s infinite alternate-reverse ease-in-out;
}

#welcome .crystal-illu-container .crystal-medium {
    position: absolute;
    height: 200px;
    bottom: 0;
    right: 0;
    animation: crystalFloatVerticalMedium 5s infinite alternate ease-in-out;
}

#welcome .crystal-illu-container .crystal-small {
    position: absolute;
    height: 90px;
    top: 0;
    left: 0;
    animation: crystalFloatVerticalSmall 3s infinite alternate ease-in-out;
}

#welcome .price-card {
    position: relative;
    background: linear-gradient(87.22deg, rgba(255, 255, 255, 0.4) -1.84%, rgba(255, 255, 255, 0.084) 110.25%);
    box-shadow: 0 4px 64px rgba(8, 34, 66, 0.09);
    border-radius: 21px;
    max-width: 500px;
    min-width: 330px;
    width: 90vw;
    height: 110px;

    @include media-breakpoint-down(sm) {
        width: 95vw;
    }
}

#welcome .price-card.blur {
    position: absolute;
    background: linear-gradient(87.22deg, rgba(255, 255, 255, 0.4) -1.84%, rgba(195, 190, 255, 0.084) 110.25%);
    opacity: 0.8;
    backdrop-filter: blur(7px);
    border-radius: 21px;
    max-width: 500px;
    min-width: 330px;
    width: 90vw;
    height: 110px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;

    @include media-breakpoint-down(sm) {
        width: 95vw;
    }
}

#welcome .price-card-content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        padding: 0 12px;
    }
}

#welcome .price-card .price-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

#welcome .price-card .price-text .small-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    color: #515151;
    @include media-breakpoint-down(sm) {
        font-size: 12px;
    }
}

#welcome .price-card .price-text .big-text {
    font-family: $primary_font;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: #515151;

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}

@include media-breakpoint-down(sm) {
    .get-lum-button {
        padding: 12px;
        border-radius: 12px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .get-lum-button > strong {
        font-size: 14px;
    }
}

#welcome .arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid $color-arrow-green;
}

#welcome .arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 6px solid $color-arrow-red;
}

@keyframes crystalFloatVerticalSmall {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(10px);
    }
}

@keyframes crystalFloatVerticalMedium {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(15px);
    }
}
@keyframes crystalFloatVerticalLarge {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(25px);
    }
}

@import '_main';

#lum {
    background: linear-gradient(180deg, #07223f 0.01%, #0375ed 100%);
    color: $color-light;
    padding-top: 120px;
    padding-bottom: 120px;

    @include media-breakpoint-down(lg) {
        padding-top: 64px;
    }
}

#lum .universal-reward h1 {
    font-style: normal;
    font-weight: $regular;
    font-size: 2.625rem;
    line-height: 3.125rem;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    @include media-breakpoint-down(lg) {
        margin-top: 12px;
    }
}

#lum .universal-reward p {
    font-style: normal;
    font-weight: $regular;
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding: 0;
    margin: 0;
    max-width: 460px;

    @include media-breakpoint-down(lg) {
        max-width: none;
    }
}

#lum .universal-reward .spotlight-lumreward {
    animation: rewardFloatVerticalLarge 7s infinite alternate-reverse ease-in-out;
}

#lum .universal-reward .reward-blue-medium {
    position: absolute;
    min-width: auto;
    width: 90px;
    right: -20px;
    bottom: 60px;
    animation: rewardFloatVerticalMedium 5s infinite alternate ease-in-out;

    @include media-breakpoint-down(lg) {
        width: 68px;
        right: -30px;
        bottom: 30px;
    }
}

#lum .universal-reward .reward-blue-small {
    position: absolute;
    min-width: auto;
    width: 97px;
    left: -10px;
    top: 10px;
    animation: rewardFloatVerticalSmall 3s infinite alternate ease-in-out;

    @include media-breakpoint-down(lg) {
        width: 72px;
        left: -20px;
        top: 5px;
    }
}

@keyframes rewardFloatVerticalSmall {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(10px);
    }
}

@keyframes rewardFloatVerticalMedium {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(15px);
    }
}
@keyframes rewardFloatVerticalLarge {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(25px);
    }
}

#lum .powered-by {
    position: relative;
    margin-top: 160px;
    margin-bottom: 120px;
}

#lum .powered-by h1 {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: $regular;
    font-size: 10rem;
    line-height: 11rem;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
        font-size: 7rem;
        line-height: 8rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 5.5rem;
        line-height: 6rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 5rem;
        line-height: 5.5rem;
        text-align: start;
    }
}

#lum .powered-by h1.part-1 {
    width: 100%;
}

#lum .powered-by h1.part-2 {
    z-index: 2;

    @include media-breakpoint-down(sm) {
        width: 100%;
        z-index: 0;
    }
}

#lum .powered-by img.part-3 {
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 144px;
    margin-left: 40px;
    padding-bottom: 10px;
    width: auto;

    @include media-breakpoint-down(lg) {
        height: 102px;
        margin-left: 28px;
        padding-bottom: 7px;
    }

    @include media-breakpoint-down(md) {
        height: 80px;
        margin-left: 22px;
        padding-bottom: 6px;
    }

    @include media-breakpoint-down(sm) {
        height: 72px;
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 40px;
        padding-bottom: 0px;
        padding-left: 2px;
    }
}

#lum .powered-by img.section-content-illu {
    position: absolute;
    width: 400px;
    height: auto;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    z-index: 0;

    @include media-breakpoint-down(lg) {
        top: 20px;
        width: 280px;
    }

    @include media-breakpoint-down(md) {
        top: 10px;
        width: 220px;
    }

    @include media-breakpoint-down(sm) {
        top: initial;
        bottom: 40px;
        left: 130px;
        width: 200px;
        transform: translate(0);
    }
}

#lum .powered-by p {
    font-style: normal;
    font-weight: $regular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    margin-top: 92px;
    max-width: 90%;

    @include media-breakpoint-down(lg) {
        max-width: none;
        margin-top: 32px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
}

#lum .power-card {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    padding: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(lg) {
        margin-bottom: 24px;
        height: auto;
    }

    @include media-breakpoint-down(lg) {
        padding: 22px;
    }
}

#lum .power-card img {
    object-fit: contain;
    width: 200px;
    height: 240px;

    @include media-breakpoint-down(lg) {
        width: 140px;
        height: 168px;
    }
}

#lum .power-card h2 {
    font-style: normal;
    font-weight: $bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    margin-top: 42px;
    margin-bottom: 12px;

    @include media-breakpoint-down(lg) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

#lum .power-card p {
    font-style: normal;
    font-weight: $regular;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0;
    margin: 0;
}

#lum .cta {
    margin-top: 44px;

    @include media-breakpoint-down(lg) {
        margin-top: 24px;
    }
}
